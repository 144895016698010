import { GnCarousel } from "../libraries/slick";
const SELECTORS = {
  component: ".js-slider"
};
const sliderInstance = [...document.querySelectorAll(SELECTORS.component)];
if (sliderInstance) {
  const OPTIONS = {
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5e3,
    dots: true,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableHeight: true
  };
  sliderInstance.forEach((slider) => {
    const parent = slider.parentElement;
    const slickInstance = new GnCarousel(slider, OPTIONS, parent);
    slickInstance.initCarousel();
  });
}
